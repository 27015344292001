import { Days, Interval, Time, ValidationError } from '../../global/types';
import * as api from '../../apiClient';


export enum MarketingCampaignState {
    None = 0,
    Draft = 1,
    Active = 2,
    Paused = 3,
    Complete = 4
}

export enum MarketingCampaignRunInterval {
    Manual = 0,
    Hourly = 1,
    Daily = 2,
    Weekly = 3,
    Monthly = 4,
    BiMonthly = 5,
    Quarterly = 6
}

export enum MarketingCampaignFilterType {
    None = 0,
    Age = 1,
    Birthday = 2,
    BookedActivityType = 4,
    LastVisitDate = 5,
    LastBookingEventDate = 7,
    CustomerCategory = 9,
    CustomerTag = 10,
    AbandonedWebBooking = 11,
    LastBookingCreationDate = 12,
    PostalCode = 13,
    MembershipType = 14,
    FirstVisitDate = 15
}

export enum MarketingCampaignFilterComparison
{
    EqualTo = 1,
    LessThan = 2,
    GreaterThan = 3,
    In = 4,
    Between = 5,
    InTheNext = 6,
    InTheLast = 7,
    NotInTheLast = 8
}

export enum MarketingCampaignFilterUnit {
    None = 0,
    Hour = 1,
    Day = 2,
    Week = 3,
    Month = 4
}

export enum MarketingCampaignFilterGroupType {
    None = 0,
    And = 1,
    Or = 2
}

export enum DayOfMonth {
    None = 0,
    FirstDay = 1,
    LastDay = 2,
    FirstWeekDay = 3,
    LastWeekDay = 4,
    SpecificDay = 5,
}

export enum MarketingCampaignRunStatus {
    Created = 1,
    Processing = 2,
    Discarded = 3,
    Processed = 4
}

export interface MarketingCampaign {
    id: string;
    name: string;
    clientEmailTemplateId: string;
    state: MarketingCampaignState;
    runInterval: MarketingCampaignRunInterval;
    dayOfWeekToRun: Days;
    dailyRunStartTime: Time;
    dailyRunEndTime?: Time;
    dayOfMonthToRun: DayOfMonth;
    dayOfMonthValue: number;
    startTime: Date;
    endTime: Date | null;
    minimumTimeIntervalBeforeResend: Interval;
    emailSubjectTemplate: string;
    emailHtmlTemplate: string;
    emailJsonTemplate: string;
    sendFromEmailAddressOverride: string | null;
    sendFromEmailNameOverride: string | null;
    venueFilter: string[];
    filters: MarketingCampaignFilterGroup;
    totalEmails: number;
    deliveredEmails: number;
    openedEmails: number;
    clickedEmails: number;
    bouncedEmails: number;
    nextRunTime: Date | null;
}

export interface MarketingCampaignRun {
    id: number;
    marketingCampaignId: string;
    state: MarketingCampaignRunStatus;
    createDateTime: Date;
    sent: number;
    delivered: number;
    opened: number;
    clicked: number;
    bounced: number;
    dropped: number;
    spamreported: number;
    unsubscribed: number;
    conversions: number;
    conversionValue: number;

}

export interface MarketingCampaignFilterGroup {
    filters: MarketingCampaignFilter[];
    type: MarketingCampaignFilterGroupType;
}

export interface MarketingCampaignFilter {
    type: MarketingCampaignFilterType;
    comparison: MarketingCampaignFilterComparison;
    filterUnit: MarketingCampaignFilterUnit;
    value1: string;
    value2: string | null;
    subFilters: MarketingCampaignFilterGroup | null;
}

export interface CampaignState {
    isLoading: boolean;
    showComplete: boolean;
    campaigns: MarketingCampaign[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum CampaignActionTypes {
    RequestCampaigns = 'REQUEST_CAMPAIGNS',
    ReceivedCampaigns = 'RECEIVE_CAMPAIGNS',
    SaveCampaign = 'SAVE_CAMPAIGN',
    CampaignSaved = 'CAMPAIGN_SAVED',
    CampaignSaveFailed = 'CAMPAIGN_SAVE_FAILED',
    EditCampaign = 'EDIT_CAMPAIGN',
    CampaigChanged = 'CAMPAIGN_CHANGED'
}

export interface LoadCampaigns {
    type: CampaignActionTypes.RequestCampaigns;
    showComplete: boolean;
}

export interface ReceiveCampaigns {
    type: CampaignActionTypes.ReceivedCampaigns;
    campaigns: MarketingCampaign[];
    error: api.ApiError | null;
}

export interface SaveCampaign {
    type: CampaignActionTypes.SaveCampaign;
    isNew: boolean;
    campaignId?: string;
    campaign: MarketingCampaign;
}

export interface CampaignSaved {
    type: CampaignActionTypes.CampaignSaved;
    campaignId: string;
}

export interface CampaignSaveFailed {
    type: CampaignActionTypes.CampaignSaveFailed;
    error: api.ApiError;
}

export interface EditCampaign {
    type: CampaignActionTypes.EditCampaign
}

export interface CampaigChanged {
    type: CampaignActionTypes.CampaigChanged
}