
import * as api from '../../apiClient';

import { AllProductsReportSettings, AnalyticsComparisonPeriodSelection, AnalyticsPeriod, AnalyticsPeriodSelection, AnalyticsReport, AnalyticsReportType, ComparisonPeriod, ReportSettings, RevenueReportSettings } from "./types";
import { DayOfWeek } from '../venues/types';
import { parseLocalDateTime } from '../../../utils/util';

interface GetReportsResponse {
    reports: ApiAnalyticsReport[];
}

interface ApiAnalyticsReport {
    id: number;
    name: string;
    type: AnalyticsReportType;
    period: AnalyticsPeriodSelection;
    customFromDate: Date | null;
    customToDate: Date | null;
    comparisonPeriod: AnalyticsComparisonPeriodSelection 
    customComparisonFromDate: Date | null;
    customComparisonToDate: Date | null;
    deselectedVenues: string[];
    serializedSettings: string;
}

const parseBool = (val: any, defaultVal: boolean) => val && typeof val === 'boolean' ? val : defaultVal;

const parseSettings = (type: AnalyticsReportType, settings: string) => {
    const parsed = JSON.parse(settings);
    switch (type) {
        case AnalyticsReportType.Revenue:
            const revSettings: RevenueReportSettings = {
                type: 'revenue-report',
                graphGrossValues: parseBool(parsed.graphGrossValues, false),
                tableGrossValues: parseBool(parsed.tableGrossValues, false),
                tableNetValues: parseBool(parsed.tableNetValues, false),
                graphBookings: parseBool(parsed.graphBookings, false),
                tableBookings: parseBool(parsed.tableBookings, false),
                graphOtherBills: parseBool(parsed.graphOtherBills, false),
                tableOtherBills: parseBool(parsed.tableOtherBills, false),
                graphAvgGrossOrderValue: parseBool(parsed.graphAvgGrossOrderValue, false),
                tableAvgGrossOrderValue: parseBool(parsed.tableAvgGrossOrderValue, false),
                graphAvgNetOrderValue: parseBool(parsed.graphAvgNetOrderValue, false),
                tableAvgNetOrderValue: parseBool(parsed.tableAvgNetOrderValue, false),
            }
            return revSettings;
        case AnalyticsReportType.Products:
            const prodSettings: AllProductsReportSettings = {
                type: 'all-products-report',
                graphGrossValues: parseBool(parsed.graphGrossValues, false),
                tableGrossValues: parseBool(parsed.tableGrossValues, false),
                tableNetValues: parseBool(parsed.tableNetValues, false),
                graphDiscount: parseBool(parsed.graphDiscount, false),
                tableDiscount: parseBool(parsed.tableDiscount, false),
                graphQuantity: parseBool(parsed.graphQuantity, false),
                tableQuantity: parseBool(parsed.tableQuantity, false),
                graphBills: parseBool(parsed.graphBills, false),
                tableBills: parseBool(parsed.tableBills, false),
                graphAvgGrossOrderValue: parseBool(parsed.graphAvgGrossOrderValue, false),
                tableAvgGrossOrderValue: parseBool(parsed.tableAvgGrossOrderValue, false),
                graphAvgNetOrderValue: parseBool(parsed.graphAvgNetOrderValue, false),
                tableAvgNetOrderValue: parseBool(parsed.tableAvgNetOrderValue, false),
                graphEvents: parseBool(parsed.graphEvents, false),
                tableEvents: parseBool(parsed.tableEvents, false),
                graphSessions: parseBool(parsed.graphSessions, false),
                tableSessions: parseBool(parsed.tableSessions, false),
                graphAvgBookedParticipants: parseBool(parsed.graphAvgBookedParticipants, false),
                tableAvgBookedParticipants: parseBool(parsed.tableAvgBookedParticipants, false),
                graphAvgRegisteredParticipants: parseBool(parsed.graphAvgRegisteredParticipants, false),
                tableAvgRegisteredParticipants: parseBool(parsed.tableAvgRegisteredParticipants, false),
            }
            return prodSettings;
        default:
            throw new Error("unexpected report type " + type);
    }
}

export const loadReports = (notAuthAction: () => void) => api.getWithAuth<GetReportsResponse>('api/v1/analytics/reports', notAuthAction)
    .map(r => r.reports.map<AnalyticsReport>(rep => {

        const period = rep.period === AnalyticsPeriodSelection.Custom
            ? AnalyticsPeriod.custom(parseLocalDateTime(rep.customFromDate || new Date()), parseLocalDateTime(rep.customToDate || new Date()))
            : AnalyticsPeriod.create(rep.period, DayOfWeek.Monday);

        const comparison = rep.comparisonPeriod === AnalyticsComparisonPeriodSelection.Custom
            ? ComparisonPeriod.custom(parseLocalDateTime(rep.customFromDate || new Date()), parseLocalDateTime(rep.customToDate || new Date()))
            : ComparisonPeriod.create(period, rep.comparisonPeriod)

        const settings = parseSettings(rep.type, rep.serializedSettings);
        return {
            id: rep.id,
            name: rep.name,
            type: rep.type,
            deselectedVenues: rep.deselectedVenues,
            period: period,
            comparisonPeriod: comparison,
            settings: settings
        };
    }));

export const saveAnalyticsReport = (report: AnalyticsReport, notAuthAction: () => void) => {
    const isNew = !report.id;
    const body = {
        name: report.name,
        type: report.type,
        period: report.period.period,
        customFromDate: report.period.from,
        customToDate: report.period.to,
        comparisonPeriod: report.comparisonPeriod.period,
        customComparisonFromDate: report.comparisonPeriod.from,
        CustomComparisonToDate: report.comparisonPeriod.to,
        deselectedVenues: report.deselectedVenues,
        serializedSettings: JSON.stringify(report.settings)
    };
    return isNew ? api.postWithAuth('api/v1/analytics/report', body, notAuthAction) : api.putWithAuth(`api/v1/analytics/report/${report.id}`, body, notAuthAction);
}