
import * as React from 'react';
import { IconProps } from './iconProps';

const Online = (props: IconProps) => (
    <svg style={({ width: `${props.width}px`, height: `${props.height}px` })} viewBox='0 0 512 512'>
		<g>
			<path d="M62.957,398.443h392.076c13.193,0,23.984-10.792,23.984-23.978V98.562c0-13.193-10.792-23.978-23.984-23.978
				H62.957c-13.208,0-23.985,10.785-23.985,23.978v275.903C38.972,387.651,49.749,398.443,62.957,398.443z M70.146,105.752h377.697
				v261.509H70.146V105.752z" fill={props.colour}/>
			<path d="M0,412.229v5.996c0,10.552,8.631,19.19,19.183,19.19h473.622c10.566,0,19.196-8.638,19.196-19.19v-5.996H0z" fill={props.colour} />
			<path d="M255.997,148.037c-52.352,0-94.788,42.439-94.791,94.795c0.004,52.355,42.439,94.795,94.791,94.795
				c52.359,0,94.795-42.44,94.798-94.795C350.792,190.476,308.356,148.037,255.997,148.037z M197.348,184.183
				c8.641-8.637,19.168-15.354,30.903-19.521c-5.622,6.794-10.308,15.51-13.98,25.588h-22.417
				C193.588,188.146,195.42,186.111,197.348,184.183z M185.501,199.142h25.916c-3.337,11.816-5.336,25.114-5.689,39.247h-32.552
				C173.932,224.016,178.35,210.647,185.501,199.142z M185.501,286.521c-7.151-11.505-11.569-24.875-12.324-39.248h32.584
				c0.356,14.119,2.299,27.439,5.629,39.248H185.501z M197.348,301.487c-1.928-1.935-3.761-3.969-5.494-6.074h22.431
				c1.586,4.357,3.34,8.496,5.3,12.296c2.574,4.972,5.467,9.436,8.644,13.292C216.499,316.834,205.986,310.11,197.348,301.487z
				 M251.558,325.662c-1.388-0.078-2.768-0.184-4.134-0.325c-2.179-0.975-4.34-2.316-6.48-4.054
				c-6.53-5.318-12.579-14.322-17.18-25.87h27.794V325.662z M251.558,286.521h-30.898c-3.507-11.54-5.672-24.917-6.046-39.248h36.945
				V286.521z M251.558,238.389h-36.913c0.371-14.33,2.546-27.706,6.056-39.247h30.857V238.389z M251.558,190.25h-27.756
				c1.151-2.882,2.359-5.672,3.68-8.221c3.942-7.635,8.582-13.652,13.462-17.643c2.14-1.744,4.301-3.087,6.48-4.061
				c1.366-0.142,2.747-0.247,4.134-0.311V190.25z M314.655,184.183c1.928,1.928,3.758,3.962,5.492,6.067h-22.43
				c-1.586-4.358-3.344-8.496-5.301-12.296c-2.574-4.972-5.466-9.436-8.645-13.292C295.502,168.829,306.014,175.552,314.655,184.183z
				 M260.442,160.015c1.388,0.064,2.769,0.176,4.132,0.311c2.183,0.982,4.344,2.317,6.484,4.061
				c6.533,5.318,12.579,14.316,17.18,25.864h-27.795V160.015z M260.442,199.142h30.9c3.507,11.54,5.671,24.917,6.045,39.247h-36.945
				V199.142z M260.442,247.274h36.913c-0.37,14.33-2.546,27.707-6.059,39.248h-30.854V247.274z M271.058,321.283
				c-2.14,1.738-4.301,3.072-6.484,4.054c-1.362,0.141-2.744,0.247-4.132,0.325v-30.249h27.756c-1.151,2.881-2.358,5.671-3.68,8.221
				C280.578,311.269,275.938,317.293,271.058,321.283z M314.655,301.487c-8.644,8.631-19.171,15.347-30.906,19.514
				c5.622-6.795,10.304-15.51,13.98-25.588h22.417C318.413,297.518,316.584,299.552,314.655,301.487z M326.503,286.521h-25.92
				c3.338-11.816,5.336-25.115,5.69-39.248h32.551C338.068,261.646,333.654,275.016,326.503,286.521z M338.824,238.389h-32.58
				c-0.357-14.118-2.299-27.438-5.633-39.247h25.892C333.654,210.647,338.068,224.016,338.824,238.389z" fill={props.colour}/>
		</g>
    </svg>
);

export default Online;