
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as PropTypes from 'prop-types'

import * as auth from '../../../utils/auth';

import { ApplicationState } from '../../../store';
import * as api from '../../../store/apiClient';
import * as ClientState from '../../../store/pages/clients/types';
import * as ClientActions from '../../../store/pages/clients/actions';
import * as ModalActions from '../../../store/global/modal/actions';
import ClientForm from './clientForm';
import Loading from '../../global/loading';
import { clickHandler, formatMoment } from '../../../utils/util';
import ApiError from '../../global/apiError';
import { stringComparer } from '../../../utils/comparers';
import { PaymentGateway } from '../../../store/pages/paymentGateways/types';
import { ClientGatewayFee } from '../../../store/pages/clients/types';
import Online from '../../icons/online';
import CardMachine from '../../icons/cardMachine';
import { DateFormat } from '../../../store/pages/venues/types';

interface LocalProps { }

interface LocalState {
    clients: ClientState.IClient[];
    isLoading: boolean;
    loadError: api.ApiError | null;
    paymentGateways: PaymentGateway[];
}

interface Actions {
    loadClients: () => void;
    editClient: () => void;
    showModal: (overlayComponent: JSX.Element, screenName: string, noScroll?: boolean) => void;
}

type RoutedLocalProps = LocalProps & RouteComponentProps<{}>;

type ClientsPageProps = LocalState & Actions & RoutedLocalProps;

class ClientsPage extends React.Component<ClientsPageProps> {

    static contextTypes = {
        t: PropTypes.func
    }

    componentDidMount() {
        // This method runs when the component is first added to the page
        this.props.loadClients();
    }

    render() {
        const { t } = this.context;
        const { isLoading, loadError } = this.props;

        const sysAdmin = auth.isSystemAdmin();
        if (!sysAdmin) {
            return <div className='alert alert-danger'>{t('ApiError:unauthorized')}</div>
        }

        const body = isLoading ? <Loading /> : this.renderClientsTable();

        return <section className='clientsPage'>
            <header className='section-header'>
                <div className='page-heading'>
                    <h1 className='clients_title'>{t('ClientsPage:title')}</h1>
                </div>
                <div className='section-actions right'>
                    <button className='btn btn-info' onClick={e => clickHandler(e, this.addClient)}>{t('ClientsPage:addClient')}</button>
                </div>
            </header>
            {body}
            <ApiError error={loadError} />
        </section>;
    }

    private addClient = () => {
        this.showOverlay(null, true);
    }

    private editClient = (client: ClientState.IClient) => {
        this.showOverlay(client, false);
    }

    private showOverlay = (client: ClientState.IClient | null, addingNew: boolean) => {
        this.props.editClient();
        this.props.showModal(<ClientForm isNew={addingNew} client={client} />, 'ClientForm');
    }

    getPaymentGatewayName = (paymentGatewayId: number) => {
        const gateway = this.props.paymentGateways.find(g => g.id === paymentGatewayId)
        return gateway ? gateway.name : '';
    }

    private renderClientsTable() {
        const { t } = this.context;

        const clients = this.props.clients.sort((c1, c2) => stringComparer(c1.name, c2.name)).map(c =>
            <li key={c.id} className='card'>
                <div className='card-content'>
                    <div className='card-header'><h2>{c.name}</h2></div>
                    <div className='card-body'>
                        <div style={{ padding: '10px' }}>
                            {this.renderClientFees(c)}
                        </div>
                    </div>
                    <div className='card-footer'>
                        <button className='btn btn-link card-edit' onClick={e => clickHandler(e, () => this.editClient(c))}>{t('Global:edit')}</button>
                    </div>
                </div>
            </li>
        );

        return <ul className='card-list'>
            {clients}
        </ul>;
    }

    renderClientFees = (client: ClientState.IClient) => {
        if (!client.paymentGatewayFees) return null;

        const feesByPaymentMethod = client.paymentGatewayFees.groupBy<ClientGatewayFee, number>(f => f.paymentGatewayId);
        var paymentMethodKeys = Array.from(feesByPaymentMethod.keys());
        return <table className='table table-condensed'>
            <tbody>
                {paymentMethodKeys.map(k => {
                    const fees = feesByPaymentMethod.get(k);
                    let rows: JSX.Element[] = [];
                    if (fees && fees.length > 0) {
                        fees.forEach((fee, ix) => rows.push(<tr key={`${k}_${fee.clientGatewayFeeId}`}>
                            <td>{ix === 0 ? <label>{this.getPaymentGatewayName(k)}</label> : null}</td>
                            <td>{fee.effectiveFrom ? fee.effectiveFrom.toShortDateString(DateFormat.DMY) : null}</td>
                            <td><div style={{ display: 'flex' }}><Online width={20} height={20} colour='#337ab7' /> {fee.onlineFeePercentage}%</div></td>
                            <td><div style={{ display: 'flex' }}><CardMachine width={20} height={20} colour='#337ab7' /> {fee.cardMachineFeePercentage}%</div></td>
                        </tr>));
                    }

                    return rows;
                })}
            </tbody>
        </table>
    }
}

const matStateToProps = (state: ApplicationState) => ({
    clients: state.clients.clients,
    isLoading: state.clients.isLoading,
    loadError: state.clients.loadError,
    paymentGateways: state.paymentGateways.paymentGateways
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadClients: bindActionCreators(ClientActions.actionCreators.loadClients, dispatch),
    editClient: bindActionCreators(ClientActions.actionCreators.editClient, dispatch),
    showModal: bindActionCreators(ModalActions.actionCreators.showModal, dispatch),
    closeModal: bindActionCreators(ModalActions.actionCreators.closeModal, dispatch),
});

// Wire up the React component to the Redux store
export default connect(matStateToProps, mapDispatchToProps)(ClientsPage);

