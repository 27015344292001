
import * as React from 'react';
import { IconProps } from './iconProps';

const CardMachine = (props: IconProps) => (
	<svg style={({ width: `${props.width}px`, height: `${props.height}px` })} viewBox='0 0 612 612'>
		<g>
			<g>
				<path d="M343.82,6.876H41.258C18.47,6.876,0,25.353,0,48.135v515.73c0,22.782,18.47,41.258,41.258,41.258H343.82
			c22.782,0,41.258-18.477,41.258-41.258V48.135C385.079,25.353,366.602,6.876,343.82,6.876z M123.775,543.236
			c0,7.591-6.161,13.753-13.753,13.753H55.011c-7.598,0-13.753-6.161-13.753-13.753v-41.258c0-7.592,6.154-13.753,13.753-13.753
			h55.011c7.592,0,13.753,6.161,13.753,13.753V543.236z M123.775,446.966c0,7.592-6.161,13.753-13.753,13.753H55.011
			c-7.598,0-13.753-6.161-13.753-13.753v-41.258c0-7.592,6.154-13.753,13.753-13.753h55.011c7.592,0,13.753,6.161,13.753,13.753
			V446.966z M123.775,350.697c0,7.592-6.161,13.753-13.753,13.753H55.011c-7.598,0-13.753-6.161-13.753-13.753v-41.258
			c0-7.592,6.154-13.753,13.753-13.753h55.011c7.592,0,13.753,6.161,13.753,13.753V350.697z M123.775,254.427
			c0,7.592-6.161,13.753-13.753,13.753H55.011c-7.598,0-13.753-6.161-13.753-13.753v-41.258c0-7.592,6.154-13.753,13.753-13.753
			h55.011c7.592,0,13.753,6.161,13.753,13.753V254.427z M233.798,543.236c0,7.591-6.161,13.753-13.753,13.753h-55.011
			c-7.598,0-13.753-6.161-13.753-13.753v-41.258c0-7.592,6.154-13.753,13.753-13.753h55.011c7.592,0,13.753,6.161,13.753,13.753
			V543.236z M233.798,446.966c0,7.592-6.161,13.753-13.753,13.753h-55.011c-7.598,0-13.753-6.161-13.753-13.753v-41.258
			c0-7.592,6.154-13.753,13.753-13.753h55.011c7.592,0,13.753,6.161,13.753,13.753V446.966z M233.798,350.697
			c0,7.592-6.161,13.753-13.753,13.753h-55.011c-7.598,0-13.753-6.161-13.753-13.753v-41.258c0-7.592,6.154-13.753,13.753-13.753
			h55.011c7.592,0,13.753,6.161,13.753,13.753V350.697z M233.798,254.427c0,7.592-6.161,13.753-13.753,13.753h-55.011
			c-7.598,0-13.753-6.161-13.753-13.753v-41.258c0-7.592,6.154-13.753,13.753-13.753h55.011c7.592,0,13.753,6.161,13.753,13.753
			V254.427z M343.82,543.236c0,7.591-6.161,13.753-13.753,13.753h-55.011c-7.598,0-13.753-6.161-13.753-13.753V405.708
			c0-7.592,6.154-13.753,13.753-13.753h55.011c7.592,0,13.753,6.161,13.753,13.753V543.236z M343.82,350.697
			c0,7.592-6.161,13.753-13.753,13.753h-55.011c-7.598,0-13.753-6.161-13.753-13.753v-41.258c0-7.592,6.154-13.753,13.753-13.753
			h55.011c7.592,0,13.753,6.161,13.753,13.753V350.697z M343.82,254.427c0,7.592-6.161,13.753-13.753,13.753h-55.011
			c-7.598,0-13.753-6.161-13.753-13.753v-41.258c0-7.592,6.154-13.753,13.753-13.753h55.011c7.592,0,13.753,6.161,13.753,13.753
			V254.427z M343.82,144.404c0,7.592-6.161,13.753-13.753,13.753H55.011c-7.598,0-13.753-6.161-13.753-13.753v-75.64
			c0-7.592,6.154-13.753,13.753-13.753h275.056c7.592,0,13.753,6.161,13.753,13.753V144.404z" fill={props.colour}/>
				<path d="M412.584,316.315h185.663c7.591,0,13.753-6.161,13.753-13.753V158.157H412.584V316.315z M543.236,185.237
			c0-3.562,2.888-6.45,6.443-6.45h35.242c3.562,0,6.45,2.888,6.45,6.45v35.242c0,3.555-2.888,6.443-6.45,6.443h-35.242
			c-3.555,0-6.443-2.888-6.443-6.443V185.237z" fill={props.colour}/>
				<path d="M598.247,68.764H412.584v48.135H612V82.517C612,74.925,605.839,68.764,598.247,68.764z" fill={props.colour} />
			</g>
		</g>

    </svg>
);

export default CardMachine;