
import * as React from 'react';
import * as PropTypes from 'prop-types'

import * as ct from '../../global/controls';
import * as api from '../../../store/apiClient';

import { clickHandler, formatCurrencyVal, mapLocalDateTime, parseLocalDateTime } from '../../../utils/util';
import Pagination from '../../global/pagination';
import ApiError from '../../global/apiError';
import Loading from '../../global/loading';
import { DateFormat } from '../../../store/pages/venues/types';

interface CampaignRunConversionsProps {
    campaignId: string;
    campaignRunId: number;
    dateFormat: DateFormat;
    close: () => void;
    logout: () => void;
}

interface CampaignRunConversionsState {
    pageNumber: number;
    pageSize: number;
    search: string;
    totalConversionCount: number;
    maxPageNumber: number;
    conversions: CampaignRunConversion[];
    loading: boolean;
    loadError: api.ApiError | null;
}

interface CampaignRunConversion {
    id: string;
    billNumber: string;
    bookingDate: Date;
    eventDate: Date;
    customerFirstname: string;
    customerLastname: string;
    customerEmailAddress: string;
    primaryActivityName: string;
    billTotal: number;
    dateOfPreviousBooking: Date | null;
    numberOfPreviousBookings: number;
}

interface GetCampaignRunConversionsResponse {
    totalConversionCount: number;
    maxPageNumber: number;
    conversions: CampaignRunConversion[];
}

class CampaignRunConversions extends React.Component<CampaignRunConversionsProps, CampaignRunConversionsState> {

    searchTimeout: NodeJS.Timeout | null;

    constructor(props: CampaignRunConversionsProps) {
        super(props);

        this.searchTimeout = null;

        this.state = {
            pageNumber: 1,
            pageSize: 25,
            search: '',
            totalConversionCount: 0,
            maxPageNumber: 0,
            conversions: [],
            loading: false,
            loadError: null
        }
    }

    static contextTypes = {
        t: PropTypes.func
    }

    loadCustomers = () => {
        const { campaignId, campaignRunId } = this.props;
        const { pageNumber, pageSize, search } = this.state;

        if (this.searchTimeout) clearTimeout(this.searchTimeout);

        this.setState({ loading: true });

        api.getWithAuth<GetCampaignRunConversionsResponse>(`api/v1/campaign/${campaignId}/run/${campaignRunId}/conversions?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`, this.props.logout)
            .subscribe(
                resp => this.setState({
                    loading: false,
                    loadError: null,
                    conversions: resp.conversions.map(c => ({ ...c, bookingDate: parseLocalDateTime(c.bookingDate), eventDate: parseLocalDateTime(c.eventDate), dateOfPreviousBooking: mapLocalDateTime(c.dateOfPreviousBooking) })),
                    totalConversionCount: resp.totalConversionCount,
                    maxPageNumber: resp.maxPageNumber
                }),
                err => this.setState({ loading: false, loadError: err  }))
    }

    componentDidMount() {
        this.loadCustomers();
    }

    clearFilter = () => this.setState({ search: '' }, this.onSearchChanged)

    searchChanged = (val: string) => this.setState({ search: val }, this.onSearchChanged)

    onSearchChanged = () => {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => this.loadCustomers(), 800);
    }

    setPage = (pageNumber: number) => this.setState({ pageNumber: pageNumber }, this.loadCustomers);

    setPageSize = (pageSize: number) => this.setState({ pageSize: pageSize }, this.loadCustomers);


    render() {
        const { t } = this.context;
        const { close, dateFormat } = this.props;
        const { search, conversions, maxPageNumber, pageNumber, pageSize, loading, loadError } = this.state;

        return <div>
            {/* header */}
            <div className="alert alert-info">
                <div className='row'>
                    <div className='col-xs-9'>
                        <ct.TextBox id='name' labelKey='CampaignRunConversions:searchLabel' placeholderKey='CampaignRunConversions:searchLabel' value={ct.asFormValue('search', search)} callback={this.searchChanged} />
                    </div>
                    <div className='col-xs-3'>
                        <div className='section-actions right' style={({ marginTop: '35px' })}>
                            <button className='btn btn-warning' onClick={e => clickHandler(e, this.clearFilter)}>
                                {t('Global:clear')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* body */}
            <div>
                {loadError
                    ? <ApiError error={loadError} />
                    : loading ? <Loading />
                        : <>
                            <table className='table table-condensed'>
                                <thead>
                                    <tr>
                                        <th>{t('CampaignRunConversions:billNumber')}</th>
                                        <th>{t('CampaignRunConversions:bookingDate')}</th>
                                        <th>{t('CampaignRunConversions:eventDate')}</th>
                                        <th>{t('CampaignRunConversions:primaryActivityName')}</th>
                                        <th className='text-right'>{t('CampaignRunConversions:billTotal')}</th>
                                        <th>{t('CampaignRunConversions:customerName')}</th>
                                        <th>{t('CampaignRunConversions:customerEmail')}</th>
                                        <th colSpan={2}>{t('CampaignRunConversions:previousBookings')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {conversions.sort((c1, c2) => c1.bookingDate.getTime() - c2.bookingDate.getTime()).map(c =>
                                        <tr key={c.id}>
                                            <td>{c.billNumber}</td>
                                            <td>{c.bookingDate.toShortDateString(dateFormat)}</td>
                                            <td>{c.eventDate.toShortDateString(dateFormat)}</td>
                                            <td>{c.primaryActivityName}</td>
                                            <td className='text-right'>{formatCurrencyVal(c.billTotal, t)}</td>
                                            <td>{c.customerFirstname} {c.customerLastname }</td>
                                            <td>{c.customerEmailAddress}</td>
                                            <td>{c.numberOfPreviousBookings}</td>
                                            <td>{c.dateOfPreviousBooking ? c.dateOfPreviousBooking.toShortDateString(dateFormat) : null}</td>
                                        </tr>)}
                                </tbody>
                            </table>
                            <Pagination maxPage={maxPageNumber} pageSize={pageSize} pageNumber={pageNumber} setPage={this.setPage} setPageSize={this.setPageSize} />
                        </>
                }
            </div>

            {/* footer */}
            <div className='btn-toolbar'>
                <button className='btn btn-basic' onClick={e => clickHandler(e, close)}>{t('Global:close')}</button>
            </div>
        </div>
    }
}

export default CampaignRunConversions;