import {ValidationError} from '../../global/types';
import * as api from '../../apiClient';


export interface IClient {
    id: number;
    name: string;
    registeredName: string;
    companyNumber: string;
    chargeSalesTax: boolean;
    salesTaxNumber: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmailAddress: string;
    billingAddressLine1: string;
    billingAddressLine2: string;
    billingAddressLine3: string;
    billingAddressLine4: string;
    billingTown: string;
    billingCounty: string;
    billingCountryId: number;
    billingPostalCode: string;
    billingEmailAddress: string;
    sendEmailsFromAddress: string;
    sendEmailsFromName: string;
    phoneNumber: string;
    websiteAddress: string;
    enableMultipleCategoriesPerCustomer: boolean;
    logoImageId: string | null;
    timeZoneId: number;
    archived: boolean;
    paymentGatewayFees: ClientGatewayFee[];
    tokens: ClientToken[];
}

export interface ClientGatewayFee {
    clientGatewayFeeId: number;
    paymentGatewayId: number;
    onlineFeePercentage: number;
    cardMachineFeePercentage: number;
    effectiveFrom: Date | null;
}

export enum ClientTokenType {
    ClientAdmin = 1
}

export interface ClientToken {
    type: ClientTokenType;
    token: string;
}

export interface ClientState {
    isLoading: boolean;
    clients: IClient[];
    loadError: api.ApiError | null;
    isSaving: boolean;
    saveComplete: boolean;
    saveError: api.ApiError | null;
    validationErrors: ValidationError[];
}

export enum ClientActionTypes {
    RequestClients = 'REQUEST_CLIENTS',
    ReceivedClients = 'RECEIVE_CLIENTS',
    SaveClient = 'CLIENT',
    ClientSaved = 'CLIENT_SAVED',
    ClientSaveFailed = 'CLIENT_SAVE_FAILED',
    EditClient = 'EDIT_CLIENT'
}

export interface LoadClients {
    type: ClientActionTypes.RequestClients;
}

export interface ReceiveClients {
    type: ClientActionTypes.ReceivedClients;
    clients: IClient[];
    error: api.ApiError | null;
}

export interface SaveClient {
    type: ClientActionTypes.SaveClient;
    isNew: boolean;
    clientId: number|null;
    client: IClient;
    logoImg: File | null;
}

export interface ClientSaved {
    type: ClientActionTypes.ClientSaved;
    clientId: number;
}

export interface ClientSaveFailed {
    type: ClientActionTypes.ClientSaveFailed;
    error: api.ApiError;
}

export interface EditClient {
    type: ClientActionTypes.EditClient
}