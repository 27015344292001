
import * as React from 'react';
import * as PropTypes from 'prop-types'

import moment from 'moment';

import * as ct from '../../global/controls';

import { ITranslationContext } from '../../../translations';
import { MarketingCampaignFilterComparison, MarketingCampaignFilterGroupType, MarketingCampaignFilterType, MarketingCampaignFilterUnit } from '../../../store/pages/campaigns/types';
import { Filter, FilterGroup } from './types';
import { clickHandler, isNullOrEmpty } from '../../../utils/util';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import CampaignFilterGroup from './campaignFilterGroup';
import { Tag } from '../../../store/pages/tags/types';
import { CustomerCategory } from '../../../store/pages/customerCategories/types';
import { Venue } from '../../../store/pages/venues/types';
import { MembershipType } from '../../../store/pages/memberships/types';
import { formatDateTimeValue, formatDateValue, formatInt, parseDateValue, parseIntValue } from './utils';

export interface CampaignFilterProps {
    level: number;
    index: number;
    filter: Filter;
    parentGroup: FilterGroup;
    owningGroupKey: string;
    venues: Venue[];
    selectedVenueIds: string[];
    activityFormats: ActivityFormat[];
    membershipTypes: MembershipType[];
    customerTags: Tag[];
    customerCategories: CustomerCategory[];
    addFilterToGroup: (parentGroupKey: string, type: MarketingCampaignFilterGroupType) => void;
    addSubFilterGroup: (parentGroupKey: string, filterKey: string, type: MarketingCampaignFilterGroupType) => void;
    onFilterTypeChanged: (key: string, val: string) => void;
    onFilterComparisonChanged: (key: string, val: string) => void;
    onfilterValueChanged: (key: string, val: string) => void;
    onfilterValue2Changed: (key: string, val: string) => void;
    onFilterValuesChanged: (key: string, val1: string, val2: string | null) => void;
    onFilterUnitChanged: (key: string, unit: MarketingCampaignFilterUnit) => void;
    removeFilter: (groupKey: string, filterKey: string) => void;
}

const CampaignFilter = (props: CampaignFilterProps, context: ITranslationContext) => {
    const { t } = context;
    const { level, index, filter, parentGroup, owningGroupKey, venues, selectedVenueIds, activityFormats,
        customerTags, customerCategories, membershipTypes, addSubFilterGroup } = props;
    const { onFilterTypeChanged, onFilterComparisonChanged, removeFilter } = props;

    const inlineControlStyle = ({ minHeight: '10px', marginRight: '10px' });

    const filterTypeOptions = [{ key: '0', name: t('CampaignPage:selectFilterType') }]
        .concat(Object.keys(MarketingCampaignFilterType)
            .filter(key => typeof MarketingCampaignFilterType[key as any] === 'number' && key !== '0')
            .filter(key => {
                var isNone = key === MarketingCampaignFilterType[MarketingCampaignFilterType.None];
                return !isNone;
            })
            .map(key => ({ key: MarketingCampaignFilterType[key as any].toString(), name: t(`MarketingCampaignFilterType:${key}`) })));

    const groupType = level > 0 || index > 0
        ? <span>{t(`MarketingCampaignFilterGroupType:${MarketingCampaignFilterGroupType[parentGroup.type.value]}`)}</span>
        : null;

    const comparisonOptions = buildFilterComparisonOptions(filter.type.value, t);
    const borderStyleOverride: React.CSSProperties = filter.subFilters && filter.subFilters.filters && filter.subFilters.filters.length > 0 ? {} : { borderWidth: '0' };

    return <div className='campaign-filter'>
        <div style={{ flex: '0 0 30px', marginTop: '18px' }}>{groupType}</div>
        <div className='campaign-filter-left-border' style={borderStyleOverride}></div>
        <div className='campaign-filter-content'>
            <div key={filter.key} className='campaign-filter'>
                <div className='inline'><ct.Select id={`${filter.key}_type`} labelKey='' value={({ ...filter.type, value: filter.type.value.toString() })} callback={val => onFilterTypeChanged(filter.key, val)} options={filterTypeOptions} style={{ ...inlineControlStyle, minWidth: '140px' }} minimal={true} /></div>
                <div className='inline'><ct.Select id={`${filter.key}_comparison`} labelKey='' value={({ ...filter.comparison, value: filter.comparison.value.toString() })} callback={val => onFilterComparisonChanged(filter.key, val)} options={comparisonOptions} style={{ ...inlineControlStyle, minWidth: '120px' }} minimal={true} /></div>
                {renderFilterValue(filter.key, filter.type.value, filter.comparison.value, filter.value1, filter.value2, filter.filterUnit, props, context, inlineControlStyle).map((c,ix) => <div key={`${filter.key}_filter_${ix}`} className='inline'>{c}</div>)}
                {parentGroup ? <div className='inline'><span onClick={e => clickHandler(e, () => removeFilter(owningGroupKey, filter.key))} className='glyphicon glyphicon-trash red' style={({ cursor: 'pointer', padding: '5px' })}></span></div> : null}
            </div>
            {filter.subFilters && filter.subFilters.filters && filter.subFilters.filters.length > 0
                ? <CampaignFilterGroup
                    level={level + 1}
                    group={filter.subFilters}
                    venues={venues}
                    selectedVenueIds={selectedVenueIds}
                    activityFormats={activityFormats}
                    membershipTypes={membershipTypes}
                    customerTags={customerTags}
                    customerCategories={customerCategories}
                    addFilterToGroup={props.addFilterToGroup}
                    addSubFilterGroup={addSubFilterGroup}
                    onFilterTypeChanged={props.onFilterTypeChanged}
                    onFilterComparisonChanged={props.onFilterComparisonChanged}
                    onfilterValueChanged={props.onfilterValueChanged}
                    onfilterValue2Changed={props.onfilterValue2Changed}
                    onFilterValuesChanged={props.onFilterValuesChanged}
                    onFilterUnitChanged={props.onFilterUnitChanged}
                    removeFilter={props.removeFilter}
                />
                : level === 0 ? <button className='btn btn-link' onClick={e => clickHandler(e, () => props.addSubFilterGroup(parentGroup.key, filter.key, MarketingCampaignFilterGroupType.Or))}>{t('CampaignPage:addOrFilter')}</button> : null
            }
        </div>
    </div>
}

const buildFilterComparisonOptions = (filterType: MarketingCampaignFilterType, t: (key: string) => string) => {
    let filteredCampaignFilterTypes: MarketingCampaignFilterComparison[] = [];

    switch (filterType) {
        case MarketingCampaignFilterType.Age:
            filteredCampaignFilterTypes = [MarketingCampaignFilterComparison.GreaterThan, MarketingCampaignFilterComparison.LessThan, MarketingCampaignFilterComparison.EqualTo, MarketingCampaignFilterComparison.Between]
            break;
        case MarketingCampaignFilterType.Birthday:
            filteredCampaignFilterTypes = [MarketingCampaignFilterComparison.InTheNext, MarketingCampaignFilterComparison.InTheLast, MarketingCampaignFilterComparison.NotInTheLast]
            break;
        case MarketingCampaignFilterType.BookedActivityType:
        case MarketingCampaignFilterType.MembershipType:
            filteredCampaignFilterTypes = [MarketingCampaignFilterComparison.EqualTo]
            break;
        case MarketingCampaignFilterType.FirstVisitDate:
        case MarketingCampaignFilterType.LastVisitDate:
        case MarketingCampaignFilterType.LastBookingEventDate:
        case MarketingCampaignFilterType.AbandonedWebBooking:
        case MarketingCampaignFilterType.LastBookingCreationDate:
            filteredCampaignFilterTypes = [MarketingCampaignFilterComparison.GreaterThan, MarketingCampaignFilterComparison.LessThan, MarketingCampaignFilterComparison.EqualTo, MarketingCampaignFilterComparison.Between, MarketingCampaignFilterComparison.InTheNext, MarketingCampaignFilterComparison.InTheLast, MarketingCampaignFilterComparison.NotInTheLast]
            break;
        case MarketingCampaignFilterType.CustomerTag:
            filteredCampaignFilterTypes = [MarketingCampaignFilterComparison.EqualTo]
            break;
        case MarketingCampaignFilterType.CustomerCategory:
            filteredCampaignFilterTypes = [MarketingCampaignFilterComparison.EqualTo]
            break;
        case MarketingCampaignFilterType.PostalCode:
            filteredCampaignFilterTypes = [MarketingCampaignFilterComparison.In]
            break;
    }

    return filteredCampaignFilterTypes.map(ct => ({ key: ct.toString(), name: t(`MarketingCampaignFilterComparison:${MarketingCampaignFilterComparison[ct]}`) }));
}

const renderFilterValue = (key: string, type: MarketingCampaignFilterType, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, value2: ct.FormValue<string | null>, unit: ct.FormValue<MarketingCampaignFilterUnit>, props: CampaignFilterProps, context: ITranslationContext, inlineControlStyle: React.CSSProperties): JSX.Element[] => {
    if (!(type in MarketingCampaignFilterType)) {
        return [<div className='text-danger'>{context.t('CampaignPage:filterTypeNotSelected')}</div>]
    }

    switch (type) {
        case MarketingCampaignFilterType.Age:
            return renderNumericFilterValue(key, comparison, value1, value2, props, context, inlineControlStyle)
        case MarketingCampaignFilterType.Birthday:
            return renderIntervalFilterValue(key, comparison, value1, value2, unit, props, context, inlineControlStyle)
        case MarketingCampaignFilterType.BookedActivityType:
            return renderActivityFormatFilterValue(key, comparison, value1, props, context, inlineControlStyle)
        case MarketingCampaignFilterType.MembershipType:
            return renderMembershipTypeFilterValue(key, comparison, value1, props, context, inlineControlStyle)
        case MarketingCampaignFilterType.LastBookingEventDate:
        case MarketingCampaignFilterType.FirstVisitDate:
        case MarketingCampaignFilterType.LastVisitDate:
        case MarketingCampaignFilterType.AbandonedWebBooking:
        case MarketingCampaignFilterType.LastBookingCreationDate:
            return comparison === MarketingCampaignFilterComparison.InTheNext || comparison === MarketingCampaignFilterComparison.InTheLast || comparison === MarketingCampaignFilterComparison.NotInTheLast
                ? renderIntervalFilterValue(key, comparison, value1, value2, unit, props, context, inlineControlStyle)
                : renderDateFilterValue(key, comparison, value1, value2, props, inlineControlStyle);
        case MarketingCampaignFilterType.CustomerTag:
            return renderCustomerTagFilterValue(key, comparison, value1, props, context, inlineControlStyle)
        case MarketingCampaignFilterType.CustomerCategory:
            return renderCustomerCategoryFilterValue(key, comparison, value1, props, context, inlineControlStyle)
        case MarketingCampaignFilterType.PostalCode:
            return renderPostalCodeFilterValue(key, comparison, value1, props, context, inlineControlStyle)
        default:
            return [<div>{`Unknown filter type ${MarketingCampaignFilterType[type]}`}</div>]
    }
}

const renderDateFilterValue = (key: string, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, value2: ct.FormValue<string | null>, props: CampaignFilterProps, inlineControlStyle: React.CSSProperties) => {
    switch (comparison) {
        case MarketingCampaignFilterComparison.EqualTo:
        case MarketingCampaignFilterComparison.GreaterThan:
        case MarketingCampaignFilterComparison.LessThan:
            return [<ct.DatePicker id={`${key}_value1`} labelKey='' value={{ ...value1, value: parseDateValue(value1.value) }} callback={val => props.onfilterValueChanged(key, formatDateValue(val, ''))} dateFormat='D/MM/YYYY' style={inlineControlStyle} />]
        case MarketingCampaignFilterComparison.Between:
            return [<ct.DateRangePicker id={`${key}_value1`} labelKey='' value={ct.asFormValue(`${key}_value1`, { from: parseDateValue(value1.value), to: parseDateValue(value2.value) })} dateFormat={'D/MM/YYYY'} timeFormat={'HH:mm'} callback={val => props.onFilterValuesChanged(key, formatDateTimeValue(val.from, ''), formatDateTimeValue(val.to, null))} style={inlineControlStyle} />]
        default:
            return [<div>{`Unknown comparison ${MarketingCampaignFilterComparison[comparison]}`}</div>]
    }
}

const renderNumericFilterValue = (key: string, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, value2: ct.FormValue<string | null>, props: CampaignFilterProps, context: ITranslationContext, inlineControlStyle: React.CSSProperties) => {
    switch (comparison) {
        case MarketingCampaignFilterComparison.EqualTo:
        case MarketingCampaignFilterComparison.GreaterThan:
        case MarketingCampaignFilterComparison.LessThan:
            return [<ct.NumberBox id={`${key}_value1`} labelKey='' value={{ ...value1, value: parseIntValue(value1.value) }} callback={val => props.onfilterValueChanged(key, formatInt(val, ''))} style={{ ...inlineControlStyle, width: '110px' }} min='1' minimal={true}  />]
        case MarketingCampaignFilterComparison.Between:
            return [<ct.NumberBox id={`${key}_value1`} labelKey='' value={{ ...value1, value: parseIntValue(value1.value) }} callback={val => props.onfilterValueChanged(key, formatInt(val, ''))} style={{ ...inlineControlStyle, width: '110px' }} min='1' minimal={true} />,
                <div className='inline' style={{ marginTop: '6px' }}>{context.t('MarketingCampaignFilterGroupType:And')}</div>,
                <ct.NumberBox id={`${key}_value2`} labelKey='' value={{ ...value2, value: parseIntValue(value2.value) }} callback={val => props.onfilterValue2Changed(key, formatInt(val, ''))} style={{ ...inlineControlStyle, width: '110px', marginLeft: '10px' }} minimal={true} />]
        default:
            return [<div>{`Unknown comparison ${MarketingCampaignFilterComparison[comparison]}`}</div>]
    }
}

const renderIntervalFilterValue = (key: string, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, value2: ct.FormValue<string | null>, unit: ct.FormValue<MarketingCampaignFilterUnit>, props: CampaignFilterProps, context: ITranslationContext, inlineControlStyle: React.CSSProperties) => {
    const { t } = context;
    const { onFilterUnitChanged } = props;

    const unitOptions = Object.keys(MarketingCampaignFilterUnit)
        .filter(key => typeof MarketingCampaignFilterUnit[key as any] === 'number')
        .map(key => ({ key: MarketingCampaignFilterUnit[key as any].toString(), name: t(`MarketingCampaignFilterUnit:${key}`) }))

    switch (comparison) {
        case MarketingCampaignFilterComparison.InTheNext:
        case MarketingCampaignFilterComparison.InTheLast:
        case MarketingCampaignFilterComparison.NotInTheLast:
            return [
                <ct.NumberBox id={`${key}_value1`} labelKey='' value={{ ...value1, value: parseIntValue(value1.value) }} callback={val => props.onfilterValueChanged(key, formatInt(val, ''))} min='1' style={{ ...inlineControlStyle, width: '110px' }} minimal={true} />,
                <ct.Select id={`${key}_unit`} labelKey='' value={{...unit, value: unit.value.toString()}} callback={val => onFilterUnitChanged(key, parseInt(val))} options={unitOptions} style={inlineControlStyle} minimal={true} />
            ]
        default:
            return [<div>{`Unknown comparison ${MarketingCampaignFilterComparison[comparison]}`}</div>]
    }
}

const renderActivityFormatFilterValue = (key: string, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, props: CampaignFilterProps, context: ITranslationContext, inlineControlStyle: React.CSSProperties) => {
    const { t } = context;
    const { venues, selectedVenueIds, activityFormats } = props;

    const activityFormatOptions = [{ key: '', name: `<${t('CampaignPage:anyActivity')}>` }]
        .concat(activityFormats
            .filter(f => selectedVenueIds.length === 0 || selectedVenueIds.indexOf(f.venueId) >= 0)
            .map(f => {
                const venue = venues.find(v => v.id === f.venueId);
                return { key: f.id, name: venue && venues.length > 1 ? `${f.name} (${venue.name})` : f.name };
            })
            .sort((o1, o2) => o1.name.localeCompare(o2.name)));

    const selectedOption = activityFormatOptions.find(t => t.key === value1.value) || activityFormatOptions[0];
    const value = { ...value1, value: selectedOption.key };

    switch (comparison) {
        case MarketingCampaignFilterComparison.EqualTo:
            return [
                <ct.Select id={`${key}_activity_format`} labelKey='' value={value} callback={val => props.onfilterValueChanged(key, val)} options={activityFormatOptions} style={inlineControlStyle} minimal={true} />
            ]
        default:
            return [<div>{`Unknown comparison ${MarketingCampaignFilterComparison[comparison]}`}</div>]
    }
}

const renderMembershipTypeFilterValue = (key: string, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, props: CampaignFilterProps, context: ITranslationContext, inlineControlStyle: React.CSSProperties) => {
    const { t } = context;
    const { membershipTypes } = props;

    const membershipTypeOptions = [{ key: '', name: `<${t('CampaignPage:anyMembershipType')}>` }]
        .concat(membershipTypes
            .map(mt => {
                return { key: mt.id, name: mt.name };
            })
            .sort((o1, o2) => o1.name.localeCompare(o2.name)));

    const selectedOption = membershipTypeOptions.find(t => t.key === value1.value) || membershipTypeOptions[0];
    const value = { ...value1, value: selectedOption.key };

    switch (comparison) {
        case MarketingCampaignFilterComparison.EqualTo:
            return [
                <ct.Select id={`${key}_membership_type`} labelKey='' value={value} callback={val => props.onfilterValueChanged(key, val)} options={membershipTypeOptions} style={inlineControlStyle} minimal={true} />
            ]
        default:
            return [<div>{`Unknown comparison ${MarketingCampaignFilterComparison[comparison]}`}</div>]
    }
}


const renderCustomerTagFilterValue = (key: string, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, props: CampaignFilterProps, context: ITranslationContext, inlineControlStyle: React.CSSProperties) => {
    const { t } = context;
    const { customerTags } = props;

    const customerTagOptions = [{ key: '', name: `<${t('CampaignPage:selectTag')}>` }]
        .concat(customerTags.map(f => {
            return {
                key: f.id, name: `${f.name}`, data: { colour: f.colour }
            };
        })
        .sort((o1, o2) => o1.name.localeCompare(o2.name)));

    const selectedOption = customerTagOptions.find(t => t.key === value1.value) || customerTagOptions[0];
    const value = { ...value1, value: selectedOption.key };

    const renderOption = (o: ct.SelectOption) => o.data && o.data.colour ? <span key={o.key} className='label tag-label' style={({ backgroundColor: o.data.colour })}>{o.name}</span> : o.name;

    switch (comparison) {
        case MarketingCampaignFilterComparison.EqualTo:
            return [
                <ct.Select id={`${key}_customer_tag`} labelKey='' value={value} callback={val => props.onfilterValueChanged(key, val)} options={customerTagOptions} renderOption={renderOption} style={inlineControlStyle} minimal={true} />
            ]
        default:
            return [<div>{`Unknown comparison ${MarketingCampaignFilterComparison[comparison]}`}</div>]
    }
}

const renderCustomerCategoryFilterValue = (key: string, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, props: CampaignFilterProps, context: ITranslationContext, inlineControlStyle: React.CSSProperties) => {
    const { t } = context;
    const { venues, selectedVenueIds, customerCategories } = props;

    const customerCategoryOptions = [{ key: '', name: `<${t('CampaignPage:selectCategory')}>` }]
        .concat(customerCategories.filter(c => selectedVenueIds.length === 0 || selectedVenueIds.indexOf(c.venueId) >= 0).map(c => {
            const venue = venues.find(v => v.id === c.venueId);
            return { key: c.id, name: venue && venues.length > 1 ? `${c.name} (${venue.name})` : c.name };
        })
        .sort((o1, o2) => o1.name.localeCompare(o2.name)));

    const selectedOption = customerCategoryOptions.find(t => t.key === value1.value) || customerCategoryOptions[0];
    const value = { ...value1, value: selectedOption.key };

    switch (comparison) {
        case MarketingCampaignFilterComparison.EqualTo:
            return [
                <ct.Select id={`${key}_customer_category`} labelKey='' value={value} callback={val => props.onfilterValueChanged(key, val)} options={customerCategoryOptions} style={inlineControlStyle} minimal={true} />,
                <span>{t('CampaignPage:saparateWithCommas')}</span>
            ]
        default:
            return [<div>{`Unknown comparison ${MarketingCampaignFilterComparison[comparison]}`}</div>]
    }
}

const renderPostalCodeFilterValue = (key: string, comparison: MarketingCampaignFilterComparison, value1: ct.FormValue<string>, props: CampaignFilterProps, context: ITranslationContext, inlineControlStyle: React.CSSProperties) => {
    const { t } = context;

    switch (comparison) {
        case MarketingCampaignFilterComparison.In:
            return [
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <ct.TextBox id={`${key}_customer_category`} labelKey='' value={value1} callback={val => props.onfilterValueChanged(key, val)} style={inlineControlStyle} minimal={true} />
                    <span>{t('CampaignPage:saparateWithCommas')}</span>
                </div>
            ]
        default:
            return [<div>{`Unknown comparison ${MarketingCampaignFilterComparison[comparison]}`}</div>]
    }
}

CampaignFilter.contextTypes = {
    t: PropTypes.func
}

export default CampaignFilter;