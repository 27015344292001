
import * as React from 'react';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../store/index';
import { connect } from 'react-redux'

import { formatBgColour, isNullOrEmpty } from '../../../utils/util';
import * as rt from '../../../store/pages/resources/types';
import * as dt from '../../../store/pages/diary/types';
import DiaryReservation from './diaryReservation';
import { ActivityFormat } from '../../../store/pages/activityFormats/types';
import { Time } from '../../../store/global/types';
import { ResourceBreak } from '../../../store/pages/resources/types';
import { Venue } from '../../../store/pages/venues/types';

interface ReservationStats {
    col: number;
    left: number;
    width: number;
}

interface MappedReduxState {
    activityFormats: ActivityFormat[];
}

interface ComponentProps {
    venue: Venue;
    resource: rt.Resource;
    width: number;
    slotHeight: number;
    slotSizeInMinutes: number;
    date: Date;
    startTime: number;
    endTime: number;
    preSelectTime?: Time;
    reservations: dt.DiaryReservation[];
    breaks: ResourceBreak[]
    addReservation: (resource: rt.Resource, startTime: Date, endTime: Date) => void;
    editReservation: (reservationId: string, eventId: string) => void;
}

type ResourceProps = MappedReduxState & ComponentProps;

interface ResourceState {
    selectedStartDate: Date | null;
    selectedEndDate: Date | null;
}

class DiaryResource extends React.Component<ResourceProps, ResourceState> {

    resourceDiv: HTMLDivElement | null;

    constructor(props: ResourceProps) {
        super(props);

        this.resourceDiv = null;
        this.state = { selectedStartDate: null, selectedEndDate: null }
    }

    mouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        // Ignore clicks that aren't left button
        if (e.button !== 0) {
            return;
        }

        const selectedTime = this.getSelectedTime(e.clientY);
        if (selectedTime) {
            this.setState({ selectedStartDate: selectedTime, selectedEndDate: selectedTime.addMinutes(this.props.slotSizeInMinutes) });
        }
    }

    mouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (e.buttons !== 1 || this.resourceDiv === null) {
            return;
        }

        const selectedTime = this.getSelectedTime(e.clientY);
        if (selectedTime) {
            this.setState({ selectedEndDate: selectedTime });
        }
    }

    getSelectedTime = (clientY: number) => {
        if (this.resourceDiv !== null) {
            const { slotHeight, slotSizeInMinutes, startTime, date } = this.props;
            const rect = this.resourceDiv.getBoundingClientRect();
            const rawSlot = (clientY - rect.top) / slotHeight;
            const timeSlot = Math.floor(rawSlot) + (startTime * (60 / slotSizeInMinutes));

            const startTimeMinutes = timeSlot * slotSizeInMinutes;
            const hours = Math.floor(startTimeMinutes / 60);
            const minutes = Math.floor(startTimeMinutes - (hours * 60));
            return new Date(new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0));
        }
        return null;
    }

    mouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
        if (this.resourceDiv !== null) {

            const { selectedStartDate, selectedEndDate } = this.state;

            if (selectedStartDate && selectedEndDate) {
                this.props.addReservation(this.props.resource, selectedStartDate, selectedEndDate);
            }
        }

        // clear selection
        this.setState({ selectedStartDate: null, selectedEndDate: null });
    }

    buildSlots = (slotSizeInMinutes: number, reservations: dt.DiaryReservation[]) => {
        const slots: string[][] = [];

        for (let i = 0; i < (24 * (60 / slotSizeInMinutes)); i++) {
            const slotReservations: string[] = [];
            slots.push(slotReservations);
        }

        return slots;
    }


    calculateSlotCollisions = (reservations: dt.DiaryReservation[], slotSizeInMinutes: number) => {
        const slots = this.buildSlots(slotSizeInMinutes, reservations);

        reservations.forEach((res, id) => {
            if (res.key) {
                const { date } = this.props;
                const endTimeInMins = dt.getReservationEndTimeInMinutes(res, date) + (res.minGapAfter || 0);
                let startTimeInMins = dt.getReservationStartTimeInMinutes(res, date) - (res.minGapBefore || 0);
                let order = 1;

                if (endTimeInMins === 0) return;

                while (startTimeInMins < endTimeInMins) {
                    const timeIndex = Math.floor(startTimeInMins / slotSizeInMinutes);

                    const slot = slots[timeIndex];
                    if (slot) {
                        slot.push(res.key);
                    } else {
                        console.log('No slot found for timeIndex:' + timeIndex)
                    }

                    startTimeInMins = startTimeInMins + slotSizeInMinutes;
                }

                const slotIndex = Math.floor((endTimeInMins - 1) / slotSizeInMinutes);
                const slot = slots[slotIndex];
                if (slot && !slot.includes(res.key)) {
                    slot.push(res.key);
                }
            }
        });

        return slots;
    }

    getAttributes = (reservations: dt.DiaryReservation[], slots: string[][]) => {
        const { resource } = this.props;
        const { numberOfLanes, allowMixedGroupsInLane } = resource;
        const maxLaneCapacity = resource.maxLaneCapacity || 9999;

        const widths: number[] = [];
        const leftOffSets: number[] = [];

        for (let i = 0; i < reservations.length; i++) {
            widths.push(0);
            leftOffSets.push(0);
        }

        slots.forEach((slotReservations) => {

            // number of events in that period
            const count = slotReservations.length === 0 ? 0 : slotReservations.reduce((total, rsv) => {
                return rsv ? total + 1 : total;
            }, 0);

            if (count > 1) {
                slotReservations.forEach((rsvIndex, id) => {
                    // max number of events it is sharing a time period with determines width
                    if (rsvIndex) {
                        if (count > widths[id]) {
                            widths[id] = count;
                        }
                    }

                    if (rsvIndex && !leftOffSets[id]) {
                        leftOffSets[id] = id;
                    }
                });
            }
        });

        const reservationStats = new Map<string, ReservationStats>();

        return reservations.map((r, ix) => {

            const allOverlaps: string[] = [];
            let maxOverlaps = 0;
            let maxOverlapPosition = 0;

            if (r.key) {
                for (let i = 0; i < slots.length; i++) {
                    const slotOverlaps = slots[i];
                    if (slotOverlaps.includes(r.key) && slotOverlaps.length > 1) {
                        const overlapPosition = slotOverlaps.indexOf(r.key) + 1;
                        maxOverlaps = Math.max(maxOverlaps, slotOverlaps.length);
                        maxOverlapPosition = Math.max(maxOverlapPosition, overlapPosition);

                        for (let j = 0; j < slotOverlaps.length; j++){
                            const overlappedReservationId = slotOverlaps[j];
                            if (overlappedReservationId !== r.key && !allOverlaps.includes(overlappedReservationId))
                                allOverlaps.push(overlappedReservationId);
                        }
                    }
                }
            }

            let leftOffset = 0;
            let left = '0px';
            let width = 0;
            let col = 0;
            const numberOfBookedParticipants = r.bookedParticipants.reduce((ttl, bp) => ttl + bp.count, 0);
            let lanesRequired = Math.min(numberOfLanes, numberOfBookedParticipants / maxLaneCapacity);

            if (maxOverlaps === 0) {
                if (numberOfLanes < 2) {
                    left = '10px';
                    col = 0;
                    leftOffset = 10;
                    width = 100;
                } else {
                    if (!allowMixedGroupsInLane) lanesRequired = Math.ceil(lanesRequired);
                    width = (lanesRequired / numberOfLanes) * 100;
                    col = 0;
                    left = `${leftOffset}px`;
                }
            } else {
                const renderedOverlaps: ReservationStats[] = [];
                for (var oi = 0; oi < allOverlaps.length; oi++) {
                    const overlap = reservationStats.get(allOverlaps[oi]);
                    if (overlap) {
                        renderedOverlaps.push(overlap);
                    }
                }

                renderedOverlaps.sort((o1, o2) => o1.col - o2.col);

                if (numberOfLanes < 2) {
                    const adjustedWidth = 100 / maxOverlaps;
                    width = adjustedWidth;
                    col = this.findFirstFreeColumn(renderedOverlaps);

                    leftOffset = 10;
                    left = col < 1 ? '10px' : `${(width * (col+1)) - width}%`;
                } else {
                    leftOffset = renderedOverlaps.reduce((to, o) => to + o.width, 0);
                    left = `${leftOffset}%`
                    if (!allowMixedGroupsInLane) lanesRequired = Math.ceil(lanesRequired);
                    width = (lanesRequired / numberOfLanes) * 100;
                }
            }

            if (r.key) {
                reservationStats.set(r.key, { width: width, col: col, left: leftOffset });
            }

            return numberOfLanes > 1
                ? { reservation: r, width: `${width}%`, left: left }
                : { reservation: r, width: `calc(${width}% - ${width === 100 ? 20 : 10}px)`, left: left };
        });
    }

    findFirstFreeColumn = (renderedOverlaps: ReservationStats[]) => {
        const cols = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

        for (let oi = 0; oi < renderedOverlaps.length; oi++) {
            cols[renderedOverlaps[oi].col] = 1;
        }

        for (let i = 0; i < cols.length; i++) {
            if (cols[i] === 0) {
                return i;
            }
        }

        return cols.length + 1;
    }

    render() {

        const { slotSizeInMinutes, slotHeight, resource, date, width, startTime, endTime, reservations, activityFormats, editReservation, breaks, venue } = this.props;
        const resStyle = { width: `calc(${width}% - 10px` };
        const resourceHeight = slotHeight * (60 / slotSizeInMinutes);
        const bodyStyle = {
            backgroundColor: formatBgColour(resource.colour, 0.1),
            backgroundImage: 'linear-gradient(to bottom, #bbb 1px, transparent 1px), linear-gradient(to bottom, #d8d8d8 1px, transparent 1px)',
            backgroundSize: `100px ${resourceHeight}px, 100px ${slotHeight}px`
        };

        const laneWidth = 100 / resource.numberOfLanes;
        const lanes = resource.numberOfLanes > 1
            ? <>{Array.from(Array(resource.numberOfLanes).keys()).map(l => <div key={`lane_${l}_marker`} className='cal-resource-lane' style={{ left: `${laneWidth * l}%`, width: `${laneWidth}%` }}></div>)  }</>
            : null;

        const pixelsPerMinute = slotHeight / slotSizeInMinutes;
        const mainReservations = reservations.filter(r => !r.isPlaceholder || isNullOrEmpty(r.targetReservationId));
        const placeholderReservations = reservations.filter(r => r.isPlaceholder && !isNullOrEmpty(r.targetReservationId));

        // Passing slot size in minutes as 1 to fix issue with incorrect overlaps if sessions start / end part way through a full slot
        const mainSlots = this.calculateSlotCollisions(mainReservations, 1);
        const mainReservationMap = this.getAttributes(mainReservations, mainSlots);

        // Passing slot size in minutes as 1 to fix issue with incorrect overlaps if sessions start / end part way through a full slot
        const placeholderPeriods = this.calculateSlotCollisions(placeholderReservations, 1);
        const placeholderMap = this.getAttributes(placeholderReservations, placeholderPeriods);

        const reservationList = mainReservationMap.concat(placeholderMap)
            .sort((x1, x2) => x1.reservation.isPlaceholder && x2.reservation.isPlaceholder ? 0 : x1.reservation.isPlaceholder ? 1 : -1)
            .map(x => {
                const format = activityFormats.find(f => f.id === x.reservation.activityFormatId);

                if (x.reservation.resourceId !== resource.id) {
                    const targetRsv = x.reservation.targetReservationId && mainReservationMap.find(mr => mr.reservation.id === x.reservation.targetReservationId);

                    const startTimeInMins = Math.max(dt.getReservationStartTimeInMinutes(x.reservation, date), startTime * 60);
                    const endTimeInMins = Math.min(dt.getReservationEndTimeInMinutes(x.reservation, date), endTime * 60);
                    const top = (startTimeInMins * pixelsPerMinute) - (startTime * 60 * pixelsPerMinute);
                    const height = Math.max(10, (endTimeInMins - startTimeInMins)) * (slotHeight / slotSizeInMinutes); // Ensure resevation has a height
                    const style: React.CSSProperties = {
                        top: `${top}px`,
                        left: targetRsv ? targetRsv.left : x.left,
                        width: targetRsv ? targetRsv.width : x.width,
                        height: `${height}px`,
                    }

                    return <div key={x.reservation.key} className='cal-reservation-gap' style={style}></div>
                }
                else if (x.reservation.isPlaceholder) {
                    const targetRsv = x.reservation.targetReservationId && mainReservationMap.find(mr => mr.reservation.id === x.reservation.targetReservationId);

                    const startTimeInMins = Math.max(dt.getReservationStartTimeInMinutes(x.reservation, date), startTime * 60);
                    const endTimeInMins = Math.min(dt.getReservationEndTimeInMinutes(x.reservation, date), endTime * 60);
                    const top = (startTimeInMins * pixelsPerMinute) - (startTime * 60 * pixelsPerMinute);
                    const height = Math.max(10, (endTimeInMins - startTimeInMins)) * (slotHeight / slotSizeInMinutes); // Ensure resevation has a height
                    const style: React.CSSProperties = {
                        top: `${top}px`,
                        left: targetRsv ? targetRsv.left : x.left,
                        width: targetRsv ? targetRsv.width : x.width,
                        height: `${height}px`,
                        border: 'dashed 3px #666',
                        background: x.reservation.isSelected ? 'rgba(198,228,254,0.7)' : 'rgba(255,255,255,0.7)',
                        borderRadius: '5px',
                        boxShadow: '3px 3px #aaaaaa',
                        position: 'absolute',
                        cursor: 'not-allowed',
                        zIndex: 500
                    }

                    return <div key={x.reservation.key} style={style}>
                        <div className='pull-right circle small white-bg'>{x.reservation.activityFormatVariationScheduleSequence}</div>
                    </div>
                } else {
                    return <DiaryReservation
                        key={(x.reservation.id || 0).toString()}
                        date={date}
                        left={x.left}
                        width={x.width}
                        slotSizeInMinutes={slotSizeInMinutes}
                        slotHeight={slotHeight}
                        startTime={startTime}
                        venue={venue}
                        eventStatus={x.reservation.eventStatus}
                        outstandingAmount={x.reservation.outstandingAmount}
                        overdueAmount={x.reservation.overdueAmount}
                        outstandingDepositAmount={x.reservation.outstandingDepositAmount}
                        paymentDueDate={x.reservation.paymentDueDate}
                        overdueDate={x.reservation.overduePaymentDate}
                        depositDueDate={x.reservation.depositDueDate}
                        reservation={x.reservation}
                        maxEndTime={endTime}
                        activityFormat={format}
                        reservationSelected={editReservation} />
                }
            });

        const breakPlaceholders = breaks.map(b => {
            const startTimeInMins = b.startTime.getHours() * 60 + b.startTime.getMinutes();
            const end = b.startTime.add(b.duration);
            const endTimeInMins = end.getHours() * 60 + end.getMinutes();
            const top = (startTimeInMins * pixelsPerMinute) - (startTime * 60 * pixelsPerMinute);
            const height = Math.max(10, (endTimeInMins - startTimeInMins)) * (slotHeight / slotSizeInMinutes); // Ensure resevation has a height
            const style: React.CSSProperties = {
                top: `${top}px`,
                left: 0,
                width: '100%',
                height: `${height}px`,
                position: 'absolute',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center'
            }

            return <div key={b.id} className='cal-reservation-gap' style={style}><span style={{ fontWeight: 'bold'}}>{b.text}</span></div>
        })

        // highlight mouse selection
        let selectionOverlay: JSX.Element | null = null;
        const { selectedStartDate, selectedEndDate } = this.state;
        if (selectedStartDate && selectedEndDate) {

            const startTimeInMins = selectedStartDate.getHours() * 60 + selectedStartDate.getMinutes();
            const endTimeInMins = selectedEndDate.getHours() * 60 + selectedEndDate.getMinutes();
            const top = (startTimeInMins * (slotHeight / slotSizeInMinutes)) - (startTime * 60 * (slotHeight / slotSizeInMinutes));
            const height = (endTimeInMins - startTimeInMins) * (slotHeight / slotSizeInMinutes);

            selectionOverlay = <div style={({ position: 'absolute', backgroundColor: '#ccc', top: `${top}px`, left: '0', width: '100%', height: `${height}px` })} />;
        }

        return (
            <div className='cal-resource' style={resStyle}>
                {lanes}
                <div className='cal-resource-events' style={bodyStyle} onMouseDown={this.mouseDown} onMouseMove={this.mouseMove} onMouseUp={this.mouseUp} ref={(div: HTMLDivElement) => { this.resourceDiv = div; }}>
                    {selectionOverlay}
                    {breakPlaceholders}
                    {reservationList}
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state: ApplicationState) => {
    return {
        activityFormats: state.activityFormats.activityFormats,
        products: state.products.products
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => ({});

// Casting to prevent error where used in index.ts that isBusy is mandatory, since it is being provided by Redux.
export default connect(mapStateToProps, mapDispatchToProps)(DiaryResource);
