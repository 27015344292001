
import * as React from 'react';
import * as PropTypes from 'prop-types'
import {  VenueAllProductsStats } from "./../types";
import { ITranslationContext } from '../../../../translations';
import { stringComparer } from '../../../../utils/comparers';
import ClickableHeader from '../../../global/clickableHeader';
import { formatComparison } from './utils';
import { AllProductsReportSettings } from '../../../../store/pages/analytics/types';

enum SortColumn {
    Name, 
    Quantity,
    Net,
    Gross,
    Bills,
    AverageGrossOrderValue,
    AverageNetOrderValue,
    Events,
    Sessions,
    AvgPeoplePerBooking,
    AvgParticipants
}

interface Sort {
    column: SortColumn;
    direction: 1 | -1;
};

interface ProductsReportDetailsTableProps {
    settings: AllProductsReportSettings;
    venues: VenueAllProductsStats[];
    deselectedVenues: string[];
    showComparison: boolean;
}

const ProductsReportDetailsTable = (props: ProductsReportDetailsTableProps, context: ITranslationContext) => {
    const { deselectedVenues, venues, showComparison } = props;
    const { t } = context;

    const [sort, setSort] = React.useState<Sort>({ column: SortColumn.Quantity, direction: -1 });
    const updateSort = React.useCallback((col: SortColumn) =>
    {
        setSort({ column: col, direction: sort.column === col && sort.direction === -1 ? 1 : -1 });
    }, [sort])

    const selectedVenues = venues.filter(v => !deselectedVenues.includes(v.venueId))
    return <>{selectedVenues
        .map(v => <div key={`${v.venueId}_table_wrapper`} className='analytics-report-data-table'>
            {venues.length > 1 ? <h4 style={{ backgroundColor: v.colour }}>{v.venueName}</h4> : null}
            <table className='table table-striped table-sm table-condensed'>
                <thead>
                    <tr key='main-header'>
                        {/*<th></th>*/}
                        <ClickableHeader select={() => updateSort(SortColumn.Name)} selected={sort.column === SortColumn.Name} direction={sort.direction} headerKey='Global:name' />
                        <ClickableHeader select={() => updateSort(SortColumn.Quantity)} selected={sort.column === SortColumn.Quantity} direction={sort.direction} headerKey='AllProductsReportDataTable:quantity' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.Net)} selected={sort.column === SortColumn.Net} direction={sort.direction} headerKey='AllProductsReportDataTable:netValues' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.Gross)} selected={sort.column === SortColumn.Gross} direction={sort.direction} headerKey='AllProductsReportDataTable:grossValues' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.Bills)} selected={sort.column === SortColumn.Bills} direction={sort.direction} headerKey='AllProductsReportDataTable:bills' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.AverageGrossOrderValue)} selected={sort.column === SortColumn.AverageGrossOrderValue} direction={sort.direction} headerKey='AllProductsReportDataTable:averageGrossOrderValue' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.AverageNetOrderValue)} selected={sort.column === SortColumn.AverageNetOrderValue} direction={sort.direction} headerKey='AllProductsReportDataTable:averageNetOrderValue' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.Events)} selected={sort.column === SortColumn.Events} direction={sort.direction} headerKey='AllProductsReportDataTable:events' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.Sessions)} selected={sort.column === SortColumn.Sessions} direction={sort.direction} headerKey='AllProductsReportDataTable:sessions' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.AvgPeoplePerBooking)} selected={sort.column === SortColumn.AvgPeoplePerBooking} direction={sort.direction} headerKey='AllProductsReportDataTable:avgPeoplePerBooking' colSpan={showComparison ? 2 : 1} />
                        <ClickableHeader select={() => updateSort(SortColumn.AvgParticipants)} selected={sort.column === SortColumn.AvgParticipants} direction={sort.direction} headerKey='AllProductsReportDataTable:avgParticipants' colSpan={showComparison ? 2 : 1} />
                    </tr>
                </thead>
                <tbody>
                    {v.rawProductData
                        .sort((d1, d2) => {
                            let val = 0;
                            switch (sort.column) {
                                case SortColumn.Name:
                                    val = stringComparer(d1.groupName, d2.groupName);
                                    break;
                                case SortColumn.Quantity:
                                    val = d1.quantitySold - d2.quantitySold;
                                    break;
                                case SortColumn.Net:
                                    val = d1.netSales - d2.netSales;
                                    break;
                                case SortColumn.Gross:
                                    val = d1.grossSales - d2.grossSales;
                                    break;
                                case SortColumn.Bills:
                                    val = d1.bills - d2.bills;
                                    break;
                                case SortColumn.Events:
                                    val = d1.events - d2.events;
                                    break;
                                case SortColumn.Sessions:
                                    val = d1.sessions - d2.sessions;
                                    break;
                                case SortColumn.AverageGrossOrderValue:
                                    val = d1.averageGrossOrderValue - d2.averageGrossOrderValue;
                                    break;
                                case SortColumn.AverageNetOrderValue:
                                    val = d1.averageNetOrderValue - d2.averageNetOrderValue;
                                    break;
                                case SortColumn.AvgPeoplePerBooking:
                                    val = d1.averageBookedParticipants - d2.averageBookedParticipants;
                                    break;
                                case SortColumn.AvgParticipants:
                                    val = d1.averageRegisteredParticipants - d2.averageRegisteredParticipants;
                                    break;
                            }
                            return val * sort.direction;
                        })
                        .map(pd => {
                        return <tr key={`${v.venueId}_${pd.groupKey}`}>
                            {/*<td><input id={`${v.venueId}_${pd.groupKey}_selection`} type='checkbox' checked={false} onChange={e => { }} /></td>*/}
                            <td className='left'>{pd.groupName}{pd.archived ? <span className='label label-danger' style={{marginLeft: '6px'}}>{t('Global:archived')}</span> : null }</td>
                            <td className='bordered'>{pd.quantitySold.toFixed(0)}</td>
                            {showComparison ? <td>{formatComparison(pd.quantitySold, pd.comparisonQuantitySold)}</td> : null}
                            <td className='bordered'>{pd.netSales.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td>
                            {showComparison ? <td>{formatComparison(pd.netSales, pd.comparisonNetSales)}</td> : null}
                            <td className='bordered'>{pd.grossSales.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td>
                            {showComparison ? <td>{formatComparison(pd.grossSales, pd.comparisonGrossSales)}</td> : null}
                            <td className='bordered'>{pd.bills.toFixed(0)}</td>
                            {showComparison ? <td>{formatComparison(pd.bills, pd.comparisonBills)}</td> : null}
                            <td className='bordered'>{pd.averageGrossOrderValue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td>
                            {showComparison ? <td>{formatComparison(pd.averageGrossOrderValue, pd.comparisonAverageGrossOrderValue)}</td> : null}
                            <td className='bordered'>{pd.averageNetOrderValue.toLocaleString(v.culture, { style: 'currency', currency: v.currencyCode })}</td>
                            {showComparison ? <td>{formatComparison(pd.averageNetOrderValue, pd.comparisonAverageNetOrderValue)}</td> : null}
                            <td className='bordered'>{pd.events.toFixed(0)}</td>
                            {showComparison ? <td>{formatComparison(pd.events, pd.comparisonEvents)}</td> : null}
                            <td className='bordered'>{pd.sessions.toFixed(0)}</td>
                            {showComparison ? <td>{formatComparison(pd.sessions, pd.comparisonSessions)}</td> : null}
                            <td className='bordered'>{pd.events > 0 ? pd.averageBookedParticipants.toFixed(1) : ''}</td>
                            {showComparison ? <td>{pd.events > 0 ? formatComparison(pd.averageBookedParticipants, pd.comparisonAverageBookedParticipants) : ''}</td> : null}
                            <td className='bordered'>{pd.events > 0 ? pd.averageRegisteredParticipants.toFixed(1) : ''}</td>
                            {showComparison ? <td>{pd.events > 0 ? formatComparison(pd.averageRegisteredParticipants, pd.comparisonAverageRegisteredParticipants) : ''}</td> : null}
                        </tr>
                    })}
                </tbody>
            </table>
        </div>)}</>
}

ProductsReportDetailsTable.contextTypes = {
    t: PropTypes.func
}

export default ProductsReportDetailsTable;

